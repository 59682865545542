import React, { Component } from 'react';
import GenericPage from '../components/genericpage';
import ElliotWaveData from '../data/elliotwave.json'


const ElliotWave = () => {
  return (
    <GenericPage PageData = {ElliotWaveData} />
  )
}

export default ElliotWave